<template>
  <div class="kindhead">
    <Headernav></Headernav>
    <Retrievalnav></Retrievalnav>
    <div class="kindbody" style="display: flex">
      <ClassificationSide @setRes="getWarm"></ClassificationSide>
      <div class="kindright">
        <div class="kindtitle">
          <div class="titleleft">
            <p class="kindline"></p>
            <p class="book">{{ book }}</p>
          </div>
          <div class="kindtab">
            <!-- 根据时间接口对接完成 -->
            <p class="school">限制时间:</p>
            <el-tabs v-model="newbookName" @tab-click="newbook" class="tabsss">
              <el-tab-pane   style="width: 40px"
                v-for="(item, index) in fershDatas"
                :key="index"
                :label="item.label"
                :name="item.name">
                </el-tab-pane>
            </el-tabs>
          </div>
          <div class="kindtab">
            <p class="school">资源类型:</p>
            <el-tabs
              v-model="allbookname"
              @tab-click="kindchoose"
              class="tabsss"  v-for="(item, index) in editableTabs"
                :key="index"
            >
              <el-tab-pane
                style="width: 40px"

                :label="item.NAME"
                :name="item.NAME"
              ></el-tab-pane>
            </el-tabs>
          </div>

          <div class="tables">
            <el-table
              :data="tableData"
              stripe
              style="width: 100%; border: solid 1px #ebebeb"
              @row-click="rowbookClick"
              @cell-mouse-enter="newEnter"
              @cell-mouse-leave="newLeave"
            >
              <!-- <el-table-column prop="serialnumber" label="序号" width="180">
              </el-table-column> -->
              <el-table-column
                type="index"
                :index="freshChange"
                label="序号"
                width="170"
              >
              </el-table-column>
              <el-table-column prop="title" label="题名" width="170">
                <template slot-scope="scope">
                  <div class="fensi">{{ scope.row.title }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="author" label="责任者" width="170">
                <template slot-scope="scope">
                  <div class="fense">{{ scope.row.author }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="publisher" label="出版信息" width="170">
                 <template slot-scope="scope">
                  <div class="fense">{{ scope.row.publisher }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="isbn" label="索书号"> </el-table-column>
            </el-table>
          </div>
          <div class="blockfenyeqi">
            <el-pagination
              v-if="total / pagesize > 1"
              @size-change="newbookSizeChange"
              @current-change="newbookCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="pagesize"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Api from "../api/api_classification";
import Headernav from "../components/headernav.vue";
import Footer from "../components/footer.vue";
import Retrievalnav from "../components/retrievalnav.vue";
import ClassificationSide from "../components/ClassificationSide.vue";
export default {
  name: "NewBook", //新书通报
  data() {
    return {
      currentPage1: 1,
      pagesize: 10,
      total: "",
      book: "",
      newbookName: "",
      allbookname: "1",
      freshname: "",
      freshIndex:"" ,
      tableData: [],
      editableTabs: [],
      fershDatas: [
        { label: "全部", name: "0" },
        { label: "近一个月", name: "1" },
        { label: "近三个月", name: "3" },
        { label: "近半年", name: "6" },
        { label: "近一年", name: "12" },
      ],
      freshLength:""
      // indexx:"",
    };
  },
  components: {
    Headernav,
    Footer,
    Retrievalnav,
    ClassificationSide,
  },
  methods: {
    newEnter(row, column, cell, event) {
      this.book = row.title;
    },
    newLeave() {
      this.book = "";
    },

    freshChange(index) {
      return (this.currentPage1 - 1) * this.pagesize + index + 1;
    },
    kindchoose(name, event) {
       this.currentPage1 = 1;
      this.allbookname = name.name;
        if(this.freshname=="全部("+this.freshLength+")"){
        let params = {
        pageNum:this.currentPage1,
        pageSize:this.pagesize,
          time: this.newbookName,
        cirtypename: this.allbookname,
      };
      Api.freshBook(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      })}else{
      let params = {
        className: this.freshname,
        pageNum: this.currentPage1,
        pageSize: this.pagesize,
        time: this.newbookName,
        cirtypename: this.allbookname,
      };
      Api.freshBook(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      })}
    },
    newbook(name, event) {
      this.newbookName = name.name;
       this.currentPage1 = 1;
       if(this.freshname=="全部("+this.freshLength+")"){
        let params = {
        pageNum:this.currentPage1,
        pageSize:this.pagesize,
          time: this.newbookName,
        cirtypename: this.allbookname,
      };
      Api.freshBook(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      })}else{
      let params = {
        className: this.freshname,
        pageNum: this.currentPage1,
        pageSize: this.pagesize,
        time: this.newbookName,
        cirtypename: this.allbookname,
      };
      Api.freshBook(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      })}
    },
    rowbookClick(row, column) {
      this.$router.push({
        path: "/Retrievaldetails",
        query: {
          id: row.id,
        },
      });
    },
    newbookSizeChange(val) {
    },
    newbookCurrentChange(val) {
      if(this.freshname=="全部("+this.freshLength+")"){
          let params = {
        pageNum:this.currentPage1,
        pageSize:this.pagesize,
          time: this.newbookName,
        cirtypename: this.allbookname,
      };
      Api.freshBook(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      })}else{
         let params = {
        className: this.freshname,
        pageNum: this.currentPage1,
        pageSize: this.pagesize,
        time: this.newbookName,
        cirtypename: this.allbookname,
      };
      Api.freshBook(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });

      }
     
    },
    async getWarm(name) {
      this.currentPage1 = 1;
      let res = await Api.getBtype();
      this.editableTabs = res.data;
      this.allbookname = res.data[0].NAME;
      // this.newbookName = this.fershDatas[0].name;
       let freshLen = await  Api.classification()
       this.freshLength=freshLen.data.length
      this.freshname = name;
      if(name=="全部("+this.freshLength+")"){
        let params = {
        pageNum:this.currentPage1,
        pageSize:this.pagesize,
          time: this.newbookName,
        cirtypename: this.allbookname,
      };
      let data = await Api.freshBook(params);
      this.tableData = data.rows;
      this.total = data.total;

      }else{
        let params = {
        className: this.freshname,
        pageNum: this.currentPage1,
        pageSize: this.pagesize,
        time: this.newbookName,
        cirtypename: this.allbookname,
      };
      let data = await Api.freshBook(params);
      this.tableData = data.rows;
      this.total = data.total;
      }
      
    },
  },
  created() {

  },
};
</script>

<style  scoped>
.kindhead {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.kindbody {
  display: flex;
  justify-content: center;
}

.kindline {
  width: 3px;
  height: 21px;
  background-color: #ff7701;
}
.titleleft {
  display: flex;
  height: 52px;
  align-items: center;
  margin-bottom: 9px;
}
.book {
  margin-left: 10px;
  color: #ff7701;
  font-size: 18px;
  
}
.kindtitle {
  border-bottom: 1px solid #eeeeee;
  width: 851px;
  height: 52px;
}
.kindright {
  margin-left: 56px;
  display: flex;
  flex-direction: column;
}
.brownp {
  font-size: 14px;
  color: #000;
  
  padding-left: 15px;
}
.brownspan {
  font-size: 14px;
  color: #ff7701;
  
  padding-left: 15px;
}
.brownspa {
  font-size: 14px;
  color: #ff7701;
  
}
.kindtab {
  display: flex;
}
.school {
  font-size: 14px;
  color: #000000;
  
}
.tabss {
  padding-left: 23px;
  margin-top: 9px;
}
.tabss .el-tabs__nav-wrap::after {
  background-color: transparent;
}
::v-deep .tabss .el-tabs__item {
  /* padding: 0 19px; */
  width: 40px;
  text-align: center;
  margin-left: 20px;
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #303133; */
  position: relative;
  color: #000;
  background: #fff;
}
::v-deep .tabss .el-tabs__item.is-active {
  /* padding: 0 19px; */
  padding: 0 1px;
  width: 40px;
  /* text-align: center; */
  /* margin-left: 20px; */
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #303133; */
  position: relative;
  color: white;
  background: #ff7701;
  border-radius: 3px;
}
::v-deep .tabss .el-tabs__active-bar {
  background: transparent;
}
::v-deep .tabss .el-tabs__nav-wrap::after {
  background: transparent;
}
.tabsss {
  padding-left: 29px;
  margin-top: 9px;
}
.tabsss .el-tabs__nav-wrap::after {
  background-color: transparent;
}
::v-deep .tabsss .el-tabs__item {
  /* padding: 0 19px; */
  width: auto;
  text-align: center;
  margin-left: 20px;
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #303133; */
  position: relative;
  color: #000;
  background: #fff;
}
::v-deep .tabsss .el-tabs__item.is-active {
  /* padding: 0 19px; */
  padding: 0 1px;
  width: auto;
  /* text-align: center; */
  /* margin-left: 20px; */
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #303133; */
  position: relative;
  color: white;
  background: #ff7701;
  border-radius: 3px;
}
::v-deep .tabsss .el-tabs__active-bar {
  background: transparent;
}
::v-deep .tabsss .el-tabs__nav-wrap::after {
  background: transparent;
}
::v-deep .tabsss #tab-全部 {
  width: 40px;
}
::v-deep .tables .el-table th > .cell {
  text-align: center;
}
::v-deep .tables .el-table .cell {
  text-align: center;
}
::v-deep .tables .el-table th {
  padding: 0;
  height: 35px;
  background-color: #ff9234;
  color: white;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
}
::v-deep .tables .el-table td,
.el-table th {
  padding: 0;
}
::v-deep .tables .el-table__row {
  font-size: 12px;
  height: 42px;
}
::v-deep .tables .el-table td,
.el-table th.is-leaf {
  height: 40px;
}

.fensi {
  color: #ffa3a3 !important;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fense {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .tables .el-table__row:hover {
  color: #ff9234 !important;
}
::v-deep .tables .el-table__row:hover .fensi {
  color: #ff9234 !important;
}
/* ::v-deep .tables .el-table__row:hover .el-table_1_column_2 {
  color: #ff9234 !important;
} */
::v-deep .tables .el-table {
  
  color: black;
}
::v-deep
  .tables
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td {
  background: white;
}
::v-deep .blockfenyeqi .el-pagination {
  text-align: end;
  margin-top: 10px;
}
::v-deep .tables .el-table th > .cell {
  color: #fff;
}

/* .kindhead .footer {
  position: fixed;
} */
</style>

