<template>
  <div class="sidehead">
    <div class="sidebody">
      <div class="sideblack"></div>
      <div class="sidebrown">
        <p>中图分类</p>
      </div>
      <div class="sidebottom">
        <p>CLASSIFICATION</p>
      </div>
      <div
        class="sideall"
        v-for="(item, index) in classificationList"
        :key="index"
        @click="classifiy(index, item)"
        :class="active == index ? 'activeClass' : ''"
      >
        <p class="fenl">{{ item.name }}</p>
        <img v-show="active == index" src="../images/sidemore.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../api/api_classification";
export default {
  name: "ClassificationSide",
  data() {
    return {
      active: 0,
      classificationList: [],
    };
  },
   created() {
    Api.classification().then((res) => {
      this.classificationList = res.data;
      this.classificationList.unshift({
        name: "全部(" + res.data.length + ")",
      });
      this.classifiy(0, res.data[0]);
    });
  },
  methods: {
    classifiy(index, item) {
      this.active = index;
      this.$emit("setRes", item.name);
    },
  },
};
</script>

<style scoped>
.activeClass p {
  color: #ff9234 !important;
}
.sidehead {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sidebody {
  width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sideblack {
  width: 260px;
  height: 64px;
  background-color: #424242;
}
.sidebrown {
  width: 180px;
  height: 40px;
  background-color: #ff7701;
  margin: -40px auto;
  text-align: center;
}
.sidebrown p {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 38px;
  letter-spacing: 1px;
  color: #f6f6f6;
  line-height: 6px;
}
.sidebottom {
  width: 180px;
  height: 29px;
  background-color: #ff9234;
  margin: 40px auto;
  text-align: center;
}
.sidebottom p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 1px;
}
.fenl {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sideall {
  width: 229px;
  display: flex;
  justify-content: space-between;
  height: auto;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}
.sideall img {
  width: 10px;
  height: 18px;
}
.sideall p {
  font-size: 16px;
  color: #222222;

  margin-left: 25px;
}
.classification {
  width: 229px;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
  text-align: inherit;
  margin: 0 auto;
  color: #222222;
}
</style>
