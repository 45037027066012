import * as API from '.'
export default {
    // 获取图书分类
    classification: params => {
        return API.GET('api/opac/getClassNumber',params)
    },
    //图书详情
    bookcult: params => {
        return API.GET('api/elk/getDetails',params)
    },
     //热门推荐
     bookchooselist: params => {
        return API.GET('api/elk/getHot',params)
    },
     //新书通报
     freshBook: params => {
        return API.GET('api/elk/getNews',params)
    },
    //获取资源类型
    getBtype: params => {
        return API.GET('api/opac/getBcType',params)
    },
     //分类浏览
     getLook: params => {
        return API.GET('api/elk/classify',params)
    },

     
}